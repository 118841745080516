#ipad-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
#ipad {
    transform: rotate3d(1, 0, 0, 30deg);
    margin-bottom: 20px;
  }
  
  #ipad-border {
    height: min-content;
    width: min-content;
    padding: 2px;
    border-radius: 24px;
    background: linear-gradient(131deg, #8a8a8a 0%, hsl(0, 0%, 41%) 100%);
  }
  
  #ipad-bezel {
    background: #222222;
    border-radius: inherit;
    box-shadow: 0 0 #0000004d, 0 9px 20px #0000004a, 0 37px 37px #00000042,
      0 84px 50px #00000026, 0 149px 60px #0000000a, 0 233px 65px #00000003;
    padding: 18px;
  }
  
  #screen {
    width: 700px;
    /* aspect-ratio: 16/9; */
    aspect-ratio: 4/3;
    background-color: black;
    border-radius: 8px;
    /* padding: 12px; */
    overflow: hidden;
    position: relative;
    cursor: pointer;

  }
  #screen video {
    object-fit: cover;
  }
  .play-control{
    align-self: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3rem!important;
    height: 3rem!important;
    padding: 1rem;
    font-size: 2rem!important;

  }
  .fullScreen-control{
    bottom: 1rem;
    right: 1rem;
  }
  .control{
    position: absolute;
    padding: 0.5rem;
    width: 2rem;
    height: 2rem;
    /* background-color: rgba(0, 0, 0, 0.2); */
    color: white;
    border-radius: 100%;
    cursor: pointer;
    transition: var(--transition);
    z-index: 2;
    box-shadow: 0 0 5px 0 #595959;
  }
  #screen:hover .play-control{
    background-color: rgba(0, 0, 0, 0.5);
  }
  .control:hover, .control:focus{
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  /* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
    #ipad{
        width: 90%;
    }
    #ipad-border{
        width: 100%;
        border-radius: 16px;
    }
    #ipad-bezel{
        padding: 10px;
    }
    #screen {
        width: 100%;
    }
}
  /* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
    #ipad{
        width: 90%;
    }
    #ipad-border{
        width: 100%;
        border-radius: 16px;
    }
    #ipad-bezel{
        padding: 10px;
    }
    #screen {
        width: 100%;
    }
}