 nav .container > * {
  flex: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
 }
 nav .container > div a {
  color: var(--color-white);
}
.customNav {
  width: max-content;
  /* display: block; */
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1rem;
  display: flex;
  min-width: 90%;
  justify-content: space-between;
  gap: 0.8rem;
  border-radius: 1rem;

}
.customNav button {
  background: transparent;
  padding: 0.9rem;
  display: flex;
  color: var(--color-light);
  border-radius: 0.5rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: var(--transition);
}

.customNav button:hover {
  background: var(--color-primary-variant);
}

.customNav button.active {
  border-bottom: 2px solid var(--color-primary);
  color: var(--color-primary);
  /* box-shadow: 0 0 16px var(--color-bg-variant); */
} 

/* ======================= NavBar ======================= */

.customNav__lg,  .customNav{
  background: rgb(255 255 255 / 70%);
  -webkit-backdrop-filter: blur(15px);
  box-shadow: 0 0 5px 0 #d4d4d4;
  backdrop-filter: blur(15px);
}
.customNav__lg {
  transition: var(--transition);
}
.navbar-collapse{
  flex-grow: unset!important;
}
.navbar-nav .nav-link {
  transition: var(--transition);
}
.navbar-nav .nav-link:not(.show):hover {
  font-weight: 600;
}
.navbar-nav .nav-link.active-nav, .navbar-nav .nav-link.show {
  border-bottom: 3px solid var(--color-primary);
  color: var(--bs-navbar-active-color);
  font-weight: bolder;
}