.video-wrapper video {
    /* object-fit: cover; */
    cursor: pointer;
  }
  .video-wrapper:hover .playControl{
    background-color: rgba(0, 0, 0, 0.5);
  }
  .control:hover, .control:focus{
    background-color: rgba(0, 0, 0, 0.5);
  }
  .playControl{
    align-self: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3rem!important;
    height: 3rem!important;
    padding: 1rem;
    font-size: 3rem!important;
    background-color: rgba(0, 0, 0, 0.3)!important;
    position: absolute;
    color: white;
    border-radius: 100%;
    cursor: pointer;
    transition: var(--transition);
    z-index: 2;
    box-shadow: 0 0 5px 0 #595959;
  }
