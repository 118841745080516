.gallery_wrapper{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}
.gallery_container{
    flex: 0.325;
    padding: 0.5rem;
    border: 1px solid var(--color-light-grey);
    border-radius: 0.8rem;
    cursor: pointer;
}
.gallery_img{
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.5rem;
    aspect-ratio: 16/9;
}
.gallery_info{
    width: 100%;
    margin-top: 0.5rem;
    padding: 0.5rem 0.5rem 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}
.gallery_info span{
    display: block;
}
.gallery_info small{
    color: var(--color-secondary);
}

.gallery_wrapper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    /* object-fit: cover; */
  }
  .swiper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .mySwiper2 {
    width: 100%;
    aspect-ratio: 16/9;
  }
  .swiper-slide img[data-img-fit="cover"] {
    object-fit: cover!important;
  }
  .swiper-slide img[data-img-fit="contain"] {
    object-fit: contain!important;
  }
  .mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }
  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
    margin-left: 0.3rem!important;
  }
  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  }
  .gallery_header{
    padding: 0 1rem;
    justify-content: space-between;
    align-items: flex-end;
  }
  .gallery_header h6{
    color: var(--color-secondary);
  }

  .gallery_wrapper-scroll{
    display: block;
    columns: 12rem;
    gap: 1rem;
    /* padding: 0 15px; */
  }
  .column{
    margin-bottom: 1rem;
    break-inside: avoid;
  }
  .column #size {
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: 1rem;
  }
  .column img {
    opacity: 0.9;
   }
  .column img:hover {
    transform: scale(1.1);
    transition: transform .2s;
    opacity: 1;
   }
   .scroll-wrapper[data-scrolling="true"]{
    height: 500px;

  }
  .scroll {
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
}

.scroll[data-scrolling="true"] {
  height: 500px;
  -webkit-mask-image: linear-gradient(180deg, transparent, #fff 10%, #fff 50%, transparent);
}
.scroll .gallery_wrapper-scroll {
  white-space: nowrap;
  /* animation-delay: calc(var(--time)*-1); */
}
.scroll[data-scrolling="true"] .gallery_wrapper-scroll {
    animation: scroll var(--time) linear infinite;
    /* animation-delay: calc(var(--time)*-1); */
}


.scroll[data-scrolling="true"]:hover .gallery_wrapper-scroll {
  animation-play-state: paused;
}
@keyframes scroll {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(-700px);
  }
}

.gallery_grid_filters{
  margin: 1rem 0;

}
.gallery_filters {
  margin: 1rem 0;
  background: #f4f4f49c;
  padding: 1rem;
  gap: 1rem;
  border-radius: 0.5rem;
}
  /* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
  .gallery_wrapper{
      grid-template-columns: repeat(2, 1fr);
  }
}
  /* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
    .gallery_container {
        flex: 1 1 100%;
        margin-bottom: 1rem;
    }
    .gallery_wrapper{
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
  }
  .gallery_wrapper-scroll{
    columns: 5rem;
    gap: 0.5rem;
  }
  .column #size {
    border-radius: 0.5rem;
  }
  }
  