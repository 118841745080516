.testimonials__container {
  width: 100%;
  padding-bottom: 4rem;
}
.testimonials__container .swiper-slide{
  height: auto;
}
.client__avatar {
  width: 5rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 1rem;
  border: 2px solid var(--color-primary);
}
.testimonial {
  /* background: var(--color-light-grey); */
  background: var(--color-primary-variant);
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  height: 100%;
  width: 100%;
}
.client__review {
  color: var(--color-light);
  font-weight: 300;
  display: block;
  width: 80%;
  margin: 0.8rem auto 0;
}
.rating{
  margin: 1rem 0;
  justify-content: flex-start;
}
.rating img{
  width: 5rem;
}
.rating h5{
  margin: 0 0.5rem;
  color: var(--color-secondary);
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--color-primary);
}
/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
  .container.testimonials__container {
    width: 60%;
  }
}
/* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
  .container.testimonials__container {
    width: var(--container-width-sm);
  }
  .client__review {
    width: var(--container-width-sm);
  }
}
