* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none!important;
  user-select: none;
}
:root {
  --color-bg: #fcfcfc;
  --color-bg-variant: #CC7284;
  --color-bg-variant-trans: #cc728436;
  --color-primary: #EF93A2;
  --color-primary-variant: #ef93a242;
  --color-white: #320000;
  --color-light: rgba(44, 44, 92, 0.85);
  --color-green: #46BFB0;
  --modal-bg: #00000066;
  --color-secondary: #a5a5a5;
  --color-light-grey: #efefef;

  --bs-body-font-family: 'Open Sans', sans-serif;
  
  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none;
}
@font-face {
  font-family: 'Varela Round';
  src: url('../src/assets/fonts/VarelaRound-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-weight: 400;
  src: url('../src/assets/fonts/BalooBhaijaan2-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-weight: bold;
  src: url('../src/assets/fonts/BalooBhaijaan2-Bold.ttf') format('truetype');
}
.lang-ar{
  font-family: 'Baloo Bhaijaan 2', sans-serif !important;
  direction: rtl;
}
.lang-he{
  font-family: 'Varela Round', sans-serif !important;
  direction: rtl;
}
.lang-en{
  font-family: 'Varela Round', sans-serif !important;
  direction: ltr;
}
body {
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  /* background-image: url(../src/bg-texture.png); */
  /* background-image: linear-gradient(
    90deg,
    rgb(77 181 255 / 4%) 50%,
    transparent 50%
  ),
  linear-gradient(90deg, rgb(159 252 254 / 50%) 50%, transparent 50%);
  background-size: 10px, 0, 30px, 50px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* ======================= GENERAL STYLES ======================= */
/* .container {
  width: var(--container-width-lg);
  margin: 0 auto;
} */
.text-light {
  color: var(--color-light);
}
a {
  color: var(--color-primary);
  transition: var(--transition);
}
a:hover {
  color: var(--color-white);
}
.btn {
  width: max-content!important;
  display: inline-block!important;
  color: var(--color-primary)!important;
  padding: 0.5rem 2rem!important;
  border-radius: 0.4rem!important;
  cursor: pointer!important;
  /* border-color: transparent!important; */
  border: 1px solid var(--color-primary)!important;
  transition: var(--transition)!important;
  line-height: initial!important;
}
.btn:hover {
  background: var(--color-primary-variant)!important;
  color: var(--color-primary)!important;
  border-color: transparent!important;
}
.btn-primary {
  background: var(--color-primary)!important;
  color: var(--color-bg)!important;
  font-weight: 700!important;
}
.btn-primary:hover {
  background: var(--color-bg)!important;
  border: 1px solid var(--color-primary)!important;
}
.btn-secondary {
  background: var(--color-light)!important;
}
.btn-icon{
  margin: 0 0.5rem;
}
.pageSection{
    background: center / contain no-repeat url(../src/assets/images/partial_section_bg.png), #fff 35%;
    min-height: 50vh;
    width: 100%;
}
section h1, section h2{
  font-weight: bold;
}
section h1{
  font-size: 3rem;
  margin-bottom: 1rem;
}
section.top-index {
  height: 560px;
}
.page{
  padding-top: 3rem;
}
.scroll__settings{
  width: 100%;
  position: fixed;
  bottom: 2rem;
  right: 0;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  padding: 0 1.5rem;
}

.scroll__top-btn, .translate__button{
  font-size: 1rem;
  background: var(--color-bg);
  color: var(--color-primary);
  border-radius: 0.5rem;
  padding: 0.5rem;
  transition: var(--transition);
  cursor: pointer;
  border: 1px solid  var(--color-primary-variant);
}
.scroll__top-btn:hover, .translate__button:hover{
  /* background: var(--color-primary);
  color: var(--color-bg); */
  border-color: transparent;
  box-shadow: 0 0 5px 0 #d4d4d4;
}
.whatsapp-btn{
  background: none;
  transition: var(--transition);
  cursor: pointer;
  border-radius: 4rem;
  box-shadow: 0 0 5px 0 #828282;
}
.whatsapp-btn img{
  width: 4rem;
  height: 4rem;
}
.whatsapp-btn:hover{
 padding: 0.2rem;
 background:  var(--color-primary-variant);
 /* border: 1px solid var(--color-primary); */
}
.translate-wrapper{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
}
.absolute-element{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.flex-column-start{
  display: flex;
  flex-direction: column;
}
.flex-column-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flex-row-start{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.flex-row-center{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
  /* .container {
    width: var(--container-width-md);
  } */
  section {
    margin-top: 6rem;
  }
}
/* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
  /* .container {
    width: var(--container-width-sm);
  }*/
  /* section:has(h1,h2){
    font-size: 2.5rem;
  } */
  section h1{
    font-size: 2.5rem;
  }
  section h2{
    font-size: 2rem;
  }
  section > h2 { 
    margin-bottom: 2rem;
  }
  .whatsapp-btn{
    border-radius: 2.5rem;
  }
  .whatsapp-btn img{
    width: 2.5rem;
    height: 2.5rem;
  }
  .pageSection{
    height: auto!important;
  }
  .pageSection h1{
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  .scroll__top-btn, .translate__button{
    font-size: 0.8rem;
  }
  .scroll__settings{
    bottom: 6rem!important;
  }
}
@media print {
  .noprint {
    visibility: hidden;
  }
}
