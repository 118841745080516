header {
  /* height: 100vh; */
  padding-top: 10rem;
  overflow: hidden;
  background-image: url('../../assets/images/pattern1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-color: var(--color-bg-variant-trans); */
}
.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.button-icon{
  width: 2rem;
  /* height: 1.3rem; */
  position: absolute;
}
.celebrate-icon1{
  top: -5px;
  left: -5px;
}
.celebrate-icon2{
  bottom: -5px;
  right: -5px;
}
.custom_header{
  margin-bottom: 3rem;
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 3.5rem;
  font-family: 'Baloo Bhaijaan 2', cursive !important;
  direction: ltr;
}
.special_span1{
  position: relative;
  text-align: center;
  color: #fff;
  padding: 0 1rem;
  font-weight: bold;
}
.special_span1::before{
  content: '';
  background: var(--color-primary);
  position: absolute;
  z-index: -1;
  left: 0;
  width: 100%;
  height: 60%;
  border-radius: 5rem;
}
.bottom-rotate{
  transform: rotateZ(3deg);
}
.top-rotate{
  transform: rotateZ(-2deg);
}
.strong_underline {
  position: relative;
}
.strong_underline::after {
  content: '';
  position: absolute;
  bottom: -0.125rem;
  left: -0.5rem;
  right: -0.5rem;
  height: 0.75rem;
  z-index: -1;
  background-image: url('../../assets/images/underline.svg');
  /* background-repeat: no-repeat; */
  background-size: contain;
}
h1 > .strong_underline::after {
  bottom: 1rem;
  height: 1rem;
  left: -0.25rem;
  right: -0.25rem;
}

/* ======================= CTA ======================= */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}
/* ======================= HEADER SOCIALS ======================= */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}
.header__socials:after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}
/* ======================= HEADER BAR == ===================== */
.header__bar {
  width: 100%;
  padding: 0.5rem 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
}
.header__bar .header__logo {
  width: 100px;
  filter: drop-shadow(0 0 0.3rem var(--color-bg-variant-trans));
}
.header__logo  img {
  width: 100%;
}

.settings__wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.translate__button {
  margin: 0 0.5rem;
}
body.lang-ar .translate__button small{
  font-family: 'Varela Round', sans-serif !important;
}
body.lang-he .translate__button small{
  font-family: 'Baloo Bhaijaan 2', cursive !important;
}
.translate__button small {
  margin: 0 0.5rem;
}
.caption-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin: 2.5rem 0;
}
/* .caption-container > div{
  border: 1px solid #ccc;
} */
.caption-container .success-parties{
  flex: 0.1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.caption-container .success-parties h2{
  font-weight: bolder;
}
.caption-container .quote{
  flex: 0.55;
  font-weight: normal;
}

.caption-container .success-img{
  flex: 0.2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.caption-container .success-img img{
  height: 10rem;
}
/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
  /* header {
    height: 68vh;
  } */
}
/* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
  header {
    /* height: auto!important; */
    background-position: right top;
  }
  .header__bar {
    padding: 1rem;
  }
  .special_span1{
    padding: 0;
    color: var(--color-primary)
  }
  .special_span1::before {
    display: none;
}
.custom_header {
  font-size: 3rem;
  line-height: 3rem;
}
.cta{
  width: 100%;
    gap: 0;
    justify-content: space-between;
}
.cta .btn {
  width: 49%!important;
  font-size: 0.8rem;
  margin: 0;
  padding: 0.8rem!important;
}
.caption-container .success-img img{
  height: 8rem;
}
.caption-container .quote h5{
  font-size: 1rem;
}
.caption-container .quote{
  display: none;
}
  /* .header__bar img {
    width: 140px;
    height: 40px;
  } */
  
}
