.about_header{
    width: 100%;
    display: grid;
    grid-template-columns: 45% 50%;
    gap: 5%;
    margin: 5rem 0;
}
.about_header img{
    width: 100%;
}
.about_header p {
    font-size: 1.2rem;
}
.experience_cards{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 5rem;

}
.card{
    padding: 0.5rem!important;
    border: 1px solid var(--color-light-grey)!important;
    border-radius: 0.8rem!important;
    align-items: flex-start;
    justify-content: flex-start;
}
.icon-card{
    font-size: 2rem;
    position: relative;
}
.icon-card::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: -5px;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    background: var(--color-bg-variant-trans);
}
.card strong{ 
    margin: 1rem 0;
    /* text-align: center; */
    font-size: 1.2rem;
}
.card p{ 
    font-size: 1rem;
    /* text-align: center; */
}
/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
    .about_header{
    grid-template-columns: 55% 40%;
    }
    .experience_cards{
        grid-template-columns: repeat(2, 1fr);
    }
  }
/* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
    .about_header{
        grid-template-columns: 100%;
        margin: 3rem 0;
    }
    .about_header p {
        font-size: 1rem;
    }
    .experience_cards{
        grid-template-columns: repeat(1, 1fr);
        gap: 0.5rem;
    }
}