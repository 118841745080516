/* ======================= SERVICE MODAL ======================= */
.modal {
  position: fixed;
  inset: 0!important;
  background: var(--modal-bg)!important;
  padding: 2rem 1rem!important;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
  transition: var(--transition)!important;
  z-index: var(--z-modal);
}
.modal-visibility {
  visibility: hidden;
  display: none!important;
  opacity: 0;
}
.modal-content {
  position: relative;
  padding: 4.5rem 1.5rem 2.5rem!important;
  border-radius: 1.5rem!important;
  width: 65%!important;
  border: none!important;
  /* border: 2px solid var(--color-primary)!important; */
  text-align: center;
  height: 100%;
}
.modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: var(--color-primary-variant);
  cursor: pointer;
}
.modal-content[data-modal-content="withBg"] {
  background: var(--color-bg)!important;
}
.modal-content[data-modal-content="withoutBg"] {
  background: transparent!important;
}
.modal-content[data-modal-content="withoutBg"] .modal-close {
  top: 1rem;
  right: 1rem;
  background: var(--color-bg)!important;
  color: var(--color-primary);
  border-radius: 1.5rem;
}
/* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
  .modal-content {
    width: 100%!important;
    height: auto;
  }
}
