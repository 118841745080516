.container.contact__container {
  width: 100%;
  display: grid;
  grid-template-columns: 58% 30%;
  gap: 12%;
}
.contact__options {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 1.2rem;
  margin-bottom: 1rem;
}
.contact__option {
  width: 100%;
  background: var(--color-primary-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
  color: unset;
}
.contact__option:hover {
  background: transparent;
  border-color: var(--color-bg-variant);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.contact__option article .link {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}
.contact__option:hover article .link{
  color: var(--color-primary)!important;
}
.map-container > *{
  border-radius: 1rem;
}
.map-container, .store_info{ 
  background: none;
  padding: 0;
}
.map-container:hover, .store_info:hover {
  border-color: transparent;
}
.map-container{ 
  /* width: 50%; */
  height: 300px;
}
.store_info svg{
  font-size: 1.5rem;
  color: var(--color-primary);
}
.store_info p{
  margin-right: 1rem;
  text-align: start;
}
/* ======================= FORM ======================= */
form {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.2rem;
}
input,
textarea {
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
}
.loader-btn {
  cursor: default !important;
  padding: 0.75rem 2rem !important;
}
.loader {
  border: 0.3rem solid var(--color-bg); /* Light grey */
  border-top: 0.3rem solid var(--color-white); /* Blue */
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 2s linear infinite;
}
.image_fly_container{
  width: 100%;
  position: relative;
}
.image_fly_container .smile img, .image_fly_container .balloon img{
  width: 100%;
}
.image_fly_container .fly{
  position: absolute;
  opacity: 1;
  animation: fly 6s linear infinite;
  z-index: -1;
}
.image_fly_container .balloon{
  left: 10%;
  bottom: 30%;
}
.image_fly_container .smile{
  right: 0;
  bottom: 20%;
  transform: rotate(15deg);
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fly {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    bottom: 100%;
    transform: rotate(-30deg);
  }
}
/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
/* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
  .container.contact__container {
    width: 100%;
    display: block;
  }
  .contact__options{
    flex-direction: column;
  }
  .map-container { 
    width: 100%;
    height: 250px;
  }
}
