footer {
  background: var(--color-primary);
  /* padding: 5rem 3rem; */
  padding-bottom: 5rem;
  text-align: center;
  font-size: 0.9rem;
}
footer a {
  color: var(--color-bg);
}
.footer__line{
  width: 100%;
  height: 100px;
  background-image: url('../../assets/images/stickers_pattern.webp');
  background-repeat: repeat;
  background-size: cover;
  background-position: top;
  margin-bottom: 2rem;
}
.footer__logo {
  /* font-size: 2rem; */
  /* font-weight: 500; */
  width: 10%;
  margin-bottom: 2rem;
  /* display: inline-block; */
}
.permalinks {
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto 3rem;
}
.footer__socials {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 4rem;
}
.footer__socials a {
  background: var(--color-bg);
  color: var(--color-white);
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border: 1px solid transparent;
}
.footer__socials a:hover {
  background: transparent;
  color: var(--color-bg);
  border-color: var(--color-bg);
}
.footer__copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  color: var(--color-bg);
}
/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
  .footer__logo {
    width: 50%;
    margin-bottom: 0;
  }
  }
/* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: row;
    gap: 1.5rem;
  }
  .footer__socials {
    margin-bottom: 2.6rem;
  }
}
