.breadcrumbs__container{
    display: flex;
    width: fit-content!important;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 2rem!important;
    background: #f4f4f49c;
    border-radius: 0.5rem;
    margin-top: 3rem;
}
.current{
    font-size: 0.8rem;
    color: var(--color-secondary);
}
.active{
    color: var(--color-primary);
    font-weight: 800;
}