.services_wrapper{
    width: 100%;
    grid-gap: 0.5rem;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(4,1fr);
    /* display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap; */
    /* justify-content: space-between; */
}
.service_container{
    flex: 0.225 1 20%;
    padding: 0.5rem;
    border: 1px solid var(--color-light-grey);
    border-radius: 0.8rem;
    cursor: pointer;
    /* margin: 0.5rem; */
}
.general_services{
    width: 100%;
    gap: 1rem;

}
.general_service{
    width: 100%;
    gap: 1rem;
}
:is(.reversed){
    flex-direction: row-reverse!important;
}
.general_service .service_img {
    flex: 0.4;
}
.general_service .service_img img{
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
}
.service_info{
    flex: 0.6;
    padding-bottom: 2rem;
}
.service_info h1{
    font-weight: bolder;
    font-size: 4rem;
    color: var(--color-primary);
    margin-inline-start: 2rem;

}
/* .service_img{
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.5rem;
    aspect-ratio: 1/1;
} */
.box img {
    position: relative;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.8rem;
    aspect-ratio: 1/1;
    z-index: 1;
    transition: all 0.3s ease-in-out;
}
.content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    padding: 0 30px;
}
.box {
    position: relative;
    max-height: 300px;
    border-radius: 0.8rem;
    overflow: hidden;
    box-shadow: 0 1.4px 1.7px rgba(0, 0, 0, 0.017), 0 3.3px 4px rgba(0, 0, 0, 0.024), 0 6.3px 7.5px rgba(0, 0, 0, 0.03), 0 11.2px 13.4px rgba(0, 0, 0, 0.036), 0 20.9px 25.1px rgba(0, 0, 0, 0.043), 0 50px 60px rgba(0, 0, 0, 0.06);
}
.box .hide {
    opacity: 0;
}
.box .frame {
    position: absolute;
    border: 1px solid #fff;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.box h2, .box p {
    position: absolute;
    color: #fff;
    z-index: 2;
    width: 100%;
    transition: opacity 0.2s, transform 0.3s;
}
.box h2 {
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 0;
}
.box p {
    bottom: 0;
    font-size: 0.5rem;
}
.box:hover {
    transition: all 0.3s ease-in-out;
}
.box:hover:before {
    transition: all 0.3s ease-in-out;
}

.box img:hover {
    transition: all 0.3s ease-in-out;
}
.box img:after {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, .6);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}
.zoom-in h2 {
    top: 50%;
    transform: translatey(-50%);
    text-align: center;
    margin: 0;
}
.zoom-in p {
    text-align: center;
    top: calc(50% + 40px);
    transition: all 0.3s ease;
    transform: scale(1.8);
    opacity: 0;
}
.zoom-in:hover img {
    transform: scale(1.1);
    filter: grayscale(100%);
}
.zoom-in:hover p {
    transform: scale(1);
    transition: all 0.3s ease;
    opacity: 1;
}
.w-content .frame {
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-color: transparent;
}
.w-content h2 {
    margin-top: 0;
    top: 50%;
    transform: translatey(-50%);
    text-align: center;
}
.w-content p {
    transform: translate3d(0, -10px, 0);
}
.w-content:hover {
    opacity: 0.8;
}
.w-content:hover .frame {
    border-color: #fff;
    transition: color 0.3s ease-in-out, all 0.3s ease-in-out;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
}
.postcard h2 {
    top: 50%;
    text-align: left;
    transform: translate3d(50px, 20px, 0);
}
.postcard p {
    transform: translate3d(40px, 60px, 0);
    font-size: 24px;
}
.postcard:hover h2 {
    transform: translate3d(20px, 20px, 0);
}
.postcard:hover p {
    opacity: 1;
}
.postcard:hover img {
    filter: opacity(0.7);
    transform: translate3d(-30px, 0, 0);
}
.blury-card .frame {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.blury-card h2, .blury-card p {
    text-align: center;
    top: 50%;
    transform: translatey(-50%);
    margin: 0;
    z-index: 3;
}
.blury-card p {
    transform: translatey(30px);
}
.blury-card span {
    font-size: 24px;
}
.blury-card:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #008e6b;
    z-index: 1;
    left: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.blury-card img {
    z-index: 2;
}
.blury-card:hover .frame {
    width: calc(100% - 120px);
    height: calc(100% - 120px);
    opacity: 1;
}
.blury-card:hover img {
    opacity: 0.7;
}
.blury-card:hover:before {
    opacity: 1;
    transition: all 0.3s ease-in-out;
}
.vintage h2 {
    top: 50%;
    transform: translate3d(0, 60px, 0);
    text-align: center;
}
.vintage p {
    opacity: 0;
    bottom: 0;
    transform: translate3d(0, -10px, 0);
    font-size: 0.7rem;
    line-height: 0.8rem;
    text-align: center;
    padding: 0 0.5rem;
}
.vintage:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(44, 44, 47, 0.4);
    z-index: 2;
    bottom: 0;
    /* bottom: -100%; */
    left: 0;
}
.vintage:hover:before {
    bottom: 0;
    /* background: linear-gradient(to bottom, rgba(72, 76, 97, 0) 0%, var(--color-bg-variant-trans) 75%); */
    background: linear-gradient(to bottom, rgba(72, 76, 97, 0) 15%, var(--color-bg-variant) 100%);
}
.vintage:hover h2 {
    bottom: 40px;
    transform: translate3d(0, 30px, 0);
}
.vintage:hover p {
    opacity: 1;
    transform: translate3d(0, -30px, 0);
}


/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
    .services_wrapper{
        grid-template-columns: repeat(3,1fr);
    }
    .vintage:hover p {
        transform: translate3d(0, -10px, 0);
    }
    .vintage:hover h2 {
        transform: translate3d(0, 25px, 0);
    }
  }
  /* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
  @media screen and (max-width: 600px) {
    .services_wrapper{
        grid-template-columns: repeat(2,1fr);
    }
    .service_container {
        padding: 0.2rem;
    }
    .box h2 {
        font-size: 1rem;
        /* padding: 0 20px; */
    }
    .vintage h2 {
        transform: translate3d(0, 25px, 0);
    }
    .vintage:hover h2 {
        transform: translate3d(0, 45px, 0);
    }
    .vintage:hover p {
        transform: translate3d(0, -15px, 0);
    }
    .general_service{
        flex-direction: column!important;
    }
    .general_service > *{
        flex: 1 1!important;
        width: 100%;
    }
  }
