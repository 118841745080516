.packages_footer{
    margin-top: 2rem;
    justify-content: space-between;
    align-items: flex-end;
}
.packages_footer p{
    color: var(--color-secondary);
    width: 80%;
}
.packages_wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    gap: 1rem;
}
.package{
    background: #fff;
    flex: 0.3;
    /* margin: 0 0.5rem; */
    padding: 0 1rem;
    border-radius: 1rem;
    border: 1px solid var(--color-light-grey);
    border-top: 0.5rem solid var(--color-primary);
    transition: var(--transition);
}
.spacial_package {
    flex: 1;
    /* flex: 0.33; */
    background: var(--color-bg-variant-trans);
    /* color: #fff; */
    padding: 1rem;
}
.package:hover{
    box-shadow: 0 0 5px #ccc;
    border: 1px solid #fff;
    margin-bottom: 0.5rem;
}
.package h4 {
    /* text-align: center; */
    font-weight: bolder;
    margin: 1rem 0;
}
.package .description {
    display: block;
    /* text-align: center; */
    margin: 1rem 0!important;
    font-size: 0.8rem;
    color: var(--color-secondary);
}
.spacial_package .description {
    color: #808080;
}
.badge {
    padding: 1rem;
    border-radius: 0.5rem;
    background: var(--color-primary);
    margin-bottom: 1rem;
}
.package .option{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    font-size: 1rem;
}
.package .option .icon{
    /* border-radius: 1.5rem; */
    /* background-color: #fff;
    color: var(--color-primary); */
    color: var(--color-secondary);
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
    /* box-shadow: 0 0 2px #ccc; */
}
.spacial_package .option .icon{
    color: #808080;
}
/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
    .package, .spacial_package{
        /* flex: none; */
        /* width: 48%; */
    }
    .packages_footer p{
        width: 70%;
    }
    }
  /* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
  @media screen and (max-width: 600px) {
    .packages_wrapper {
        flex-direction: column;
    }
    .package {
        flex: 1;
        margin-top: 1rem;
        width: 100%;
    }
    .packages_footer{
        flex-direction: column;
        align-items: flex-start;
    }
    .packages_footer p{
        width: 100%;
    }
  }