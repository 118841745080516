#instagram .wrapper{
    display: grid;
    grid-template-columns: 25% 70%;
    gap: 5%;
    place-items: flex-end;
}
#instagram a{
    font-size: 1.5rem;
    text-decoration: none;
    color: inherit;
    font-weight: bold;
    transition: var(--transition);
}
#instagram a svg{
    color: var(--color-primary);
    margin-left: 1rem;
}
#instagram a:hover{
    color: var(--color-primary);
    text-decoration: underline!important;
}
.instagram_phone{
    width: 100%;
}
#instagram .wrapper .instagram_grid{
    width: 70%;
}
/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
    #instagram .wrapper{
        grid-template-columns: 35% 60%;
        place-items: center;
    }
    #instagram .wrapper .instagram_grid {
        width: 85%!important;
    }
  }
  /* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
  @media screen and (max-width: 600px) {
    
    #instagram .wrapper{
        grid-template-columns: none;
        gap: 5%;
        place-items: center;
    }
    .instagram_phone{
        width: 70%!important;
    }
    .instagram_grid{
        width: 100%!important;
    }
  }
  