.loader-container{
    position: fixed;
    inset: 0;
    background: var(--color-bg);
    display: grid;
    place-items: center;
    transition: var(--transition);
    z-index: var(--z-modal);
}
.fade-in {
    width: 50%;
    height: 50%;
    animation: fadeIn ease 1s infinite;
    -webkit-animation: fadeIn ease 1s infinite;
    -moz-animation: fadeIn ease 1s infinite;
    -o-animation: fadeIn ease 1s infinite;
    -ms-animation: fadeIn ease 1s infinite;
  }
  .fade-in img {
    width: 30%;
  }
  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
  }
}

.center-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}