section.top-index {
    height: 700px;
    margin: 0;
    padding: 0;
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev{
    color: inherit;
  }
  .swiper-button-prev:after, .swiper-button-next:after{
    font-size: 1.2rem;
    padding: 0.7rem;
    border-radius: 10px;
    /* color: #fff;
    background: rgba(0,0,0,0.4); */
  }
.aboutSlider .swiper {
    width: 80%;
    border-radius: 1rem;
    /* height: 100%; */
  }
  .aboutSlider .sliderWrapper{
    width: 100%;
    /* height: 100%; */
    border-radius: 1.5rem;
    position: relative;
  }
  .aboutSlider .swiper-slide {
    background-position: center;
    background-size: cover;
  }
  .aboutSlider .swiper-slide img {
    border-radius: 1.5rem;
    display: block;
    width: 100%;
    object-fit: cover;
  }
  .aboutSlider .swiper-slide img[data-img-preview="cover"] {
    aspect-ratio: 16 / 9;
  }
  .aboutSlider .swiper-slide img[data-img-preview="panoramic"] {
    aspect-ratio: 3 / 1;
  }
  .overlay{
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/slider/slider_overlay.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
      .title{
        font-size: 2rem;
        width: 30%;
        font-weight: bold;
        text-align: center;
    }
    .flip{
      top: 0;
      bottom: unset!important;
      transform: rotateX(180deg)!important;
  }
  .footer-shape{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    transform: rotateY(-180deg);
  }
  .footer-shape .shape{
    width:100%;
    height:14rem;
    display:block;
    position: absolute;
    z-index: 2;
  }
  .footer-shape .shape2{
    width:100%;
    height:12rem;
    display:block;
  }
  .autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--color-bg);
    font-size: 1rem;
  }
  .autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: var(--color-bg);
    fill: none;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
  }
/* ======================= MEDIA QUERIES (MEDIUM DEVICES) _tablets_ ======================= */
@media screen and (max-width: 1024px) {
  section.top-index
  {
    height: auto;
  }
}
/* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
@media screen and (max-width: 600px) {
  section.top-index
  {
    height: auto;
  }
  .title{
    font-size: 1.2rem;
    width: 50%;
}
/* .flip{
  transform: rotateX(120deg);
} */
  .footer-shape {
    bottom: 10px;
  }
  .footer-shape .shape {
    height: 5rem;
  }
  .footer-shape .shape2 {
    height: 4rem;
  }
  .aboutSlider .swiper-slide img[data-img-preview="panoramic"] {
    border-radius: 1rem;
  }
  .autoplay-progress {
    width: 30px;
    height: 30px;
    font-size: 0.6rem;
  }
}

