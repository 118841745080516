
section {
    width: 100%;
    max-width: 1920px;
    margin: auto;
    overflow: hidden;
    position: relative;
    padding: 5rem 0;
}
.section_sub_row{
    width: 100%;
    display: grid;
    grid-template-columns: 45% 50%;
    gap: 5%;
    margin-bottom: 2rem;
    align-items: end;
}
.section_sub_row_noButton{
  grid-template-columns: none;
}
.section_sub_row span{
    color: var(--color-secondary);
}
.section_button_wrapper{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1000ms ease-out, transform 400ms ease-out,
      visibility 1000ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }

  /* ======================= MEDIA QUERIES (SMALL DEVICES) _mobile_ ======================= */
  @media screen and (max-width: 600px) {
    section {
        padding: 2rem 0;
        width: var(--container-width-sm)!important;
    }
    .section_sub_row span, .section_sub_row a{
        font-size: 0.7rem!important;
    }
  }
  